// src/api/authService.ts
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

export const login = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/login`, { email, password });
    return response.data;
  } catch (err) {
    console.error('Error en la solicitud de inicio de sesión:', err);
    throw new Error('Error en el inicio de sesión');
  }
};

export const refreshToken = async (token: string) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/token`, { token });
    return response.data.accessToken;
  } catch (err) {
    console.error('Error en la solicitud de refresco de token:', err);
    throw new Error('Error en el refresco del token');
  }
};

const apiRequest = async (url: string, options: any) => {
  try {
    const response = await axios(url, options);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 403) {
      const refreshTokenValue = localStorage.getItem('refreshToken');
      if (refreshTokenValue) {
        try {
          const newAccessToken = await refreshToken(refreshTokenValue);
          localStorage.setItem('accessToken', newAccessToken);
          options.headers['Authorization'] = `Bearer ${newAccessToken}`;
          const retryResponse = await axios(url, options);
          return retryResponse.data;
        } catch (refreshError) {
          console.error('Error refrescando el token:', refreshError);
          throw new Error('Error refrescando el token');
        }
      } else {
        throw new Error('Token de refresco no disponible');
      }
    } else {
      throw error;
    }
  }
};

export default apiRequest;
