// src/components/OrdersTable.tsx
import React, { useEffect, useMemo, useState } from 'react';
import { useTable, Column } from 'react-table';
import axiosInstance from '../../api/axiosInstance';
import { useNavigate } from 'react-router-dom'; 

interface Order {
  autoIncrementId?: number;
  id: number;
  orders_id: string;
  origin_order_id: number;
  store_name: string;
  first_name: string;
  last_name: string;
  customer_full_name?: string;
  document: string;
  date_created: string;
  status: string;
  guia: string | null;
  total: string;
}

const OrdersTable: React.FC = () => {
const [orders, setOrders] = useState<Order[]>([]);
const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(1);  
const [pageSize, setPageSize] = useState(10);     
const navigate = useNavigate();

  const fetchOrders = async (page: number, limit: number) => {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axiosInstance.get(`/list?page=${page}&limit=${limit}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const { data, meta } = response.data; // Desestructuramos datos y metadatos
  
      const formattedOrders = data.map((order: Order, index: number) => ({
        ...order,
        autoIncrementId: index + 1 + (page - 1) * limit, // Ajustar el ID en base a la página
        customer_full_name: `${order.first_name ?? ''} ${order.last_name ?? ''}`,
        date_created: new Date(order.date_created).toLocaleString(),
      }));
  
      setOrders(formattedOrders);
      setCurrentPage(meta.currentPage);   // Página actual
      setTotalPages(meta.totalPages);     // Total de páginas
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };
  

  useEffect(() => {
fetchOrders(currentPage, pageSize);
  }, [currentPage, pageSize]); 
  
  const columns: Column<Order>[] = useMemo(
    () => [
/*       { Header: '# ',           accessor: 'id' as const }, */
      { Header: '# Orden',           accessor: 'orders_id' as const },
      { Header: 'Nombre Completo',   accessor: 'customer_full_name' as const},
      { Header: 'Tienda',            accessor: 'store_name' as const, Cell: ({ value }: { value: string }) => { let color = 'text-gray-500'; 
        if (value === 'Bigcenter') {color = 'text-bigcenter font-semibold'; value= 'BC'} 
        else if (value === 'Distrito Cafetero') {color = 'text-distrito font-semibold'; value='DC'} 
        else if (value === 'Hexa') {color = 'text-hexa font-semibold'; value='HX'}
         return <span className={color}>{value}</span>;},},
      { Header: 'NIT/CC',            accessor: 'document' as const },
      { Header: 'Fecha de Creación', accessor: 'date_created' as const },
      { Header: 'Estado',            accessor: 'status' as const,
        Cell: ({ value }: { value: string }) => {
          let color = 'text-gray-500 border border-sky-500'; 
          if (value === 'cancelled') {color = 'bg-gray-500 p-2 rounded-md text-white'              ; value = 'Cancelado';
          } else if (value === 'processing')     {color = 'bg-orange-500 p-2 rounded-md text-white'; value = 'Procesando';
          } else if (value === 'in-hold')        {color = 'bg-purple-500 p-2 rounded-md text-white'; value = 'En espera';
          } else if (value === 'completed')      {color = 'bg-green-500 p-2 rounded-md text-white' ; value = 'Completado';
          } else if (value === 'refused')        {color = 'bg-blue-500 p-2 rounded-md text-white'  ; value = 'Rechazado';
          } else if (value === 'failed')         {color = 'bg-red-500 p-2 rounded-md text-white'   ; value = 'Fallido';
          } else if (value === 'cnfrmdo-itrdsmo'){color = 'bg-[#001119] p-2 rounded-md text-white' ; value = 'Conf. Inter';
          } else if (value === 'verificar-trans'){color = 'bg-confirmar p-2 rounded-md text-white' ; value = 'Verificar Transferencia';
          } else if (value === 'pending')        {color = 'bg-yellow-500 p-2 rounded-md text-white'; value = 'Pendiente de pago';
          }
          return <span className={`text-center text-xs font-medium max-w-[90px] w-full truncate inline-block ${color}`}>{value}</span>;
        },
      },
      { Header: '# Guía', accessor: 'guia' as const },
      { Header: 'Total', accessor: 'total' as const },
/*       { Header: 'ID Origen', accessor: 'origin_order_id' as const },
 */    ],
    []
  );

  const tableInstance = useTable({ columns, data: orders });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {setCurrentPage(newPage);}
  };
  
  return (
    <div className="overflow-x-auto">
      <table {...getTableProps()} className="w-full table-auto divide-y divide-gray-200">
        {/* Tabla */}
        <thead className="bg-gray-50">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup-${headerGroup.id}`}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  key={`header-${column.id}`}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={`row-${row.original.autoIncrementId}`}
                onClick={() => navigate(`/list/${row.original.id}`)}
                style={{ cursor: 'pointer' }}>
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps()}
                    key={`cell-${cell.column.id}-${cell.row.id}`}
                    className="px-6 py-4 break-words text-sm text-gray-500">
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
  
      {/* Controles de paginación */}
      <div className="flex items-center justify-between mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50">
          Anterior
        </button>
        <span className="text-sm">
          Página {currentPage} de {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50">
          Siguiente
        </button>
  
        {/* Selector de tamaño de página */}
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
          className="ml-4 px-2 py-1 border rounded">
          {[5, 10, 20, 50].map((size) => (
            <option key={size} value={size}>
              {size} por página
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default OrdersTable;