import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axiosInstance';
import {ArchiveBoxXMarkIcon, PencilSquareIcon, PlusCircleIcon} from "@heroicons/react/24/outline";



interface Product {
  product_id: number;
  sku: string;
  name: string;
  description?: string;
  category_id: number;
  price: number;
  is_combo: boolean;
  tax_rate: number;
}

const Products: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [productToDelete, setProductToDelete] = useState<Product | null>(null); // Producto seleccionado para eliminar
  const navigate = useNavigate();

  const fetchProducts = async () => {
    try {
      const response = await axiosInstance.get('/products');
      setProducts(response.data);
      setError(null);
    } catch (err) {
      console.error('Error al obtener productos:', err);
      setError('No se pudieron cargar los productos.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!productToDelete) return;

    try {
      await axiosInstance.delete(`/products/${productToDelete.product_id}`);
      setProducts(products.filter((product) => product.product_id !== productToDelete.product_id));
      alert(`Producto "${productToDelete.name}" eliminado correctamente.`);
      setProductToDelete(null); // Cierra el modal
    } catch (err) {
      console.error('Error eliminando producto:', err);
      alert('Hubo un error al eliminar el producto.');
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  if (loading) {
    return <div className="text-center text-custom-gray-dark">Cargando productos...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-2xl font-bold text-custom-blue mb-4">Lista de Productos</h1>
      <button
        className="bg-custom-blue text-white px-4 py-2 rounded mb-4 flex"
        onClick={() => navigate('/products/new')}>
        <PlusCircleIcon className='h-6 w-6'/> 
        Crear 
      </button>
      <table className="w-full text-left border-collapse">
        <thead>
          <tr>
            <th className="border-b-2 p-4">SKU</th>
            <th className="border-b-2 p-4">Nombre</th>
            <th className="border-b-2 p-4">Descripción</th>
            <th className="border-b-2 p-4">Precio</th>
            <th className="border-b-2 p-4">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr
              key={product.product_id}
              className="hover:bg-gray-100 cursor-pointer"
              onClick={() => navigate(`/products/${product.product_id}`)}
            >
              <td className="p-4">{product.sku}</td>
              <td
                className="p-4 text-blue-500 underline"
                onClick={(e) => {
                  e.stopPropagation(); // Evitar que el evento se propague al `tr`
                  navigate(`/products/${product.product_id}`);
                }}
              >
                {product.name}
              </td>
              <td className="p-4">{product.description || 'N/A'}</td>
              <td className="p-4">
                {isNaN(Number(product.price)) ? '$0.00' : `$${Number(product.price).toFixed(2)}`}
              </td>
              <td className="p-4">
                <button
                  className="bg-yellow-500 text-white px-2 py-1 rounded mr-2"
                  onClick={(e) => {
                    e.stopPropagation(); // Evitar que el evento se propague al `tr`
                    navigate(`/products/${product.product_id}`);
                  }}
                >
                  <PencilSquareIcon className="h-4 w-4 text-white-500" />
                </button>
                <button
                  className="bg-red-500 text-white px-2 py-1 rounded"
                  onClick={(e) => {
                    e.stopPropagation(); // Evitar que el evento se propague al `tr`
                    setProductToDelete(product); // Muestra el modal
                  }}
                >
                  <ArchiveBoxXMarkIcon className="h-4 w-4 text-white font-bold"/>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal de confirmación */}
      {productToDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg max-w-sm w-full">
            <h2 className="text-lg font-bold mb-4">Confirmar Eliminación</h2>
            <p className="mb-4">
              ¿Estás seguro de que deseas eliminar el producto{' '}
              <strong>{productToDelete.name}</strong>?
            </p>
            <div className="flex justify-end">
              <button
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                onClick={() => setProductToDelete(null)} // Cierra el modal
              >
                Cancelar
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleDelete}
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
