//src/App.tsx
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import Profile from './components/Profile';
import Settings from './components/Settings';
import Orders from './components/Orders';
import Errors from './components/Errors';
import OrderDetails from './components/tables/OrderDetails'; 
import Dashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import Products from './components/products/Products';
import ProductForm from './components/products/ProductForm';

const App: React.FC = () => {
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
  const [userRole, setUserRole] = useState<string | null>(localStorage.getItem('userRole'));

  const handleLogin = (token: string, role: string) => {
    setToken(token);
    setUserRole(role);
    localStorage.setItem('token', token);
    localStorage.setItem('userRole', role);
  };

  const handleLogout = () => {
    setToken(null);
    setUserRole(null);
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/" element={<PrivateRoute roles={['admin']} token={token} userRole={userRole}>
          <Dashboard onLogout={handleLogout} />
        </PrivateRoute>}>
          <Route path="home" element={<Home />} />
          <Route path="profile" element={<Profile />} />
          <Route path="settings" element={<Settings />} />
          <Route path="orders" element={<Orders />} />
          <Route path="Errors" element={<Errors />} />
          <Route path="list/:id" element={<OrderDetails />} />  
          <Route path="/products" element={<Products />} />
          <Route path="/products/new" element={<ProductForm />} />
          <Route path="/products/:id" element={<ProductForm />} />
        </Route>
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;