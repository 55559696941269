// src/api/axiosInstance.ts
import axios from 'axios';
import { refreshToken } from './authService';

const BASE_URL = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const token = localStorage.getItem('refreshToken');
        if (token) {
          const newAccessToken = await refreshToken(token);
          localStorage.setItem('accessToken', newAccessToken);
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (err) {
        console.error('Error refrescando el token:', err);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
