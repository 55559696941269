import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axiosInstance';


interface ProductFormProps {}

const ProductForm: React.FC<ProductFormProps> = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    sku: '',
    name: '',
    description: '',
    category_id: 0,
    price: 0,
    is_combo: false,
    tax_rate: 0,
  });

  const isEditMode = Boolean(id);

  useEffect(() => {
    if (isEditMode) {
      const fetchProduct = async () => {
        try {
          const response = await axiosInstance.get(`/products/${id}`);
          setProduct(response.data);
        } catch (err) {
          console.error('Error obteniendo producto:', err);
        }
      };

      fetchProduct();
    }
  }, [id, isEditMode]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    if (type === 'checkbox') {
      // El elemento es un checkbox, usa 'checked'
      const { checked } = e.target as HTMLInputElement;
      setProduct({
        ...product,
        [name]: checked,
      });
    } else {
      // No es un checkbox, usa 'value'
      setProduct({
        ...product,
        [name]: value,
      });
    }
  };
  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (isEditMode) {
        await axiosInstance.put(`/products/${id}`, product);
        alert('Producto actualizado correctamente');
      } else {
        await axiosInstance.post('/products', product);
        alert('Producto creado correctamente');
      }
      navigate('/products');
    } catch (err) {
      console.error('Error guardando producto:', err);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-md rounded">
      <h1 className="text-xl font-bold mb-4">{isEditMode ? 'Editar Producto' : 'Crear Producto'}</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-medium">SKU</label>
          <input
            type="text"
            name="sku"
            value={product.sku}
            onChange={handleChange}
            className="w-full border rounded p-2"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium">Nombre</label>
          <input
            type="text"
            name="name"
            value={product.name}
            onChange={handleChange}
            className="w-full border rounded p-2"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium">Descripción</label>
          <input
            type="text"
            name="description"
            value={product.description}
            onChange={handleChange}
            className="w-full border rounded p-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium">Precio</label>
          <input
            type="number"
            name="price"
            value={product.price}
            onChange={handleChange}
            className="w-full border rounded p-2"
            required
          />
        </div>
        <button className="bg-blue-500 text-white px-4 py-2 rounded">
          {isEditMode ? 'Actualizar' : 'Crear'}
        </button>
      </form>
    </div>
  );
};

export default ProductForm;
