import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../api/axiosInstance';

interface OrderDetail {
  id: number;
  orders_id: number;
  origin_order_id: number;
  store_name: string;
  status: string;
  total: string;
  guia?: string;
  date_created: string;
  date_modified: string;
  agente?: number;
  customer: {
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
    document?: string;
    tipo_documento?: string;
    tipo_persona?: string;
    idCuenta?: number;
    idReteFuente?: string;
    idReteICA?: string;
    PctReteIVA?: string;
  };
  billingAddress: {
    address_1: string;
    city: string;
    state: string;
    country: string;
    postcode: string;
  };
  shippingAddress: {
    address_1: string;
    city: string;
    state: string;
    country: string;
    postcode: string;
  };
  lineItems: Array<{
    product_id: string;
    product_name: string;
    quantity: number;
    price: string;
  }>;
  extraDetails?: {
    servicio_utilizado?: string;
    tipo_lead?: string;
    tipo_cliente?: string;
    transportadora?: string;
    ciudad_origen?: string;
    ciudad_destino?: string;
    banco?: string;
    conocer?: string;
    id_pago?: string;
    fuente_atribucion?: string;
    marca?: string;
    nom_remitente?: string;
    cod_regional?: string;
    cod_oficina?: string;
    cod_cuenta?: string;
    forma_pago?: string;
    dir_remitente?: string;
    tel_remitente?: string;
    ced_remitente?: string;
    currency?: string;
    payment_method?: string;
    trpcol_numguia?: string;
    customer_note?: string;
    transaction_id?: string;
  };
}

const OrderDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [orderDetail, setOrderDetail] = useState<OrderDetail | null>(null);

  useEffect(() => {
    const fetchOrderDetail = async () => {
      try {
        const response = await axiosInstance.get(`/list/${id}`);
        setOrderDetail(response.data);
      } catch (error) {
        console.error('Error hacieendo fetch en detalle de pedido:', error);
      }
    };

    fetchOrderDetail();
  }, [id]);

  if (!orderDetail) {
    return <div className="text-center text-custom-gray-dark">Cargando...</div>;
  }

  return (
    <div className="max-w-7xl mx-auto p-6">
      {/* Título principal */}
      <h1 className="text-2xl font-bold text-custom-blue mb-4">
        Detalles de Pedido #{orderDetail.orders_id}
      </h1>
  
      {/* Contenedor de las tres secciones horizontales */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-4">
        {/* Información General */}
        <div className="p-6 bg-white shadow-md rounded-sm">
          <h2 className="text-xl font-semibold text-custom-blue-2 mb-2">Información General</h2>

          <p className="text-custom-gray-dark">
            <strong>Fecha Creación:</strong> {new Date(orderDetail.date_created).toLocaleString()}
          </p>
          <p className="text-custom-gray-dark">
            <strong>Fecha Edición:</strong> {new Date(orderDetail.date_modified).toLocaleString()}
          </p> 
          <p className="text-custom-gray-dark">
          <strong>Tipo Lead:</strong> {orderDetail.extraDetails?.tipo_lead || 'N/A'}
        </p>
        <p className="text-custom-gray-dark">
          <strong>Tipo Cliente:</strong> {orderDetail.extraDetails?.tipo_cliente || 'N/A'}
        </p>
          <p className="text-custom-gray-dark">
            <strong>Estado:</strong> {orderDetail.status}
          </p>
          <p className="text-custom-gray-dark">
          <strong>Marca:</strong> {orderDetail.extraDetails?.marca || 'N/A'}
        </p>
          <p className="text-custom-gray-dark">
            <strong>Guía:</strong> {orderDetail.guia || orderDetail.extraDetails?.trpcol_numguia}
          </p>
          <p className="text-custom-gray-dark">
            <strong>Agente:</strong> {orderDetail.agente || 'N/A'}
          </p>
        </div>
  
        {/* Facturación y Cliente */}
        <div className="p-6 bg-white shadow-md rounded-sm">
          <div className="mb-4">
            <h2 className="text-xl font-semibold text-custom-blue">Información del Cliente</h2>
            <p className="text-custom-gray-dark">
              <strong>Nombre:</strong> {orderDetail.customer.first_name} {orderDetail.customer.last_name}
            </p>
            <p className="text-custom-gray-dark">
              <strong>Email:</strong> {orderDetail.customer.email}
            </p>
            <p className="text-custom-gray-dark">
              <strong>Teléfono:</strong> {orderDetail.customer.phone || 'N/A'}
            </p>
            <p className="text-custom-gray-dark">
              <strong>Documento:</strong> {orderDetail.customer.document || 'N/A'}
            </p>
            <p className="text-custom-gray-dark">
              <strong>Tipo de Documento:</strong> {orderDetail.customer.tipo_documento || 'N/A'}
            </p>
          </div>
          <div>
         
          </div>
        </div>
  
        {/* Envío */}
        <div className="p-6 bg-white shadow-md rounded-sm">
        <h2 className="text-xl font-semibold text-custom-blue">Dirección de Facturación</h2>
        <p className="text-custom-gray-dark">
              <strong>Ciudad:</strong> {orderDetail.billingAddress.city}
            </p>
            <p className="text-custom-gray-dark">
              <strong>Dirección:</strong> {orderDetail.billingAddress.address_1}
            </p>
          
            <p className="text-custom-gray-dark">
              <strong>Estado:</strong> {orderDetail.billingAddress.state}
            </p>
            <p className="text-custom-gray-dark">
              <strong>País:</strong> {orderDetail.billingAddress.country}
            </p>
            <p className="text-custom-gray-dark">
              <strong>Código Postal:</strong> {orderDetail.billingAddress.postcode}
            </p>

          <h2 className="text-xl font-semibold text-custom-blue mt-2 bt-2 border-t">Dirección de Envío</h2>
          <p className="text-custom-gray-dark">
            <strong>Dirección:</strong> {orderDetail.shippingAddress.address_1}
          </p>
          <p className="text-custom-gray-dark">
            <strong>Ciudad:</strong> {orderDetail.shippingAddress.city}
          </p>
          <p className="text-custom-gray-dark">
            <strong>Estado:</strong> {orderDetail.shippingAddress.state}
          </p>
          <p className="text-custom-gray-dark">
            <strong>País:</strong> {orderDetail.shippingAddress.country}
          </p>
          <p className="text-custom-gray-dark">
            <strong>Código Postal:</strong> {orderDetail.shippingAddress.postcode}
          </p>
          <p className="text-custom-gray-dark">
          <strong>Nota Cliente:</strong> {orderDetail.extraDetails?.customer_note || 'N/A'}
        </p>
        </div>
      </div>
  

      {/* Detalles Adicionales */}
     <h2 className="text-xl font-semibold text-custom-blue-2 mb-2">Detalles Adicionales</h2>
      <div className="max-w-7xl mx-auto p-6 bg-white shadow-md rounded-sm mb-4">
      <div className="grid grid-cols-1 lg:grid-cols-3 mb-4">
        <p className="text-custom-gray-dark">
          <strong>Servicio Utilizado:</strong> {orderDetail.extraDetails?.servicio_utilizado || 'N/A'}
        </p>
        <p className="text-custom-gray-dark">
          <strong>Banco:</strong> {orderDetail.extraDetails?.banco || 'N/A'}
        </p>
        <p className="text-custom-gray-dark">
          <strong>Transportadora:</strong> {orderDetail.extraDetails?.transportadora || 'N/A'}
        </p>
        <p className="text-custom-gray-dark">
          <strong>Conocer:</strong> {orderDetail.extraDetails?.conocer || 'N/A'}
        </p>
        <p className="text-custom-gray-dark">
          <strong>ID Pago:</strong> {orderDetail.extraDetails?.id_pago || 'N/A'}
        </p>
         <p className="text-custom-gray-dark">
          <strong>Código de Cuenta:</strong> {orderDetail.extraDetails?.cod_cuenta || 'N/A'}
        </p>
        <p className="text-custom-gray-dark">
          <strong>Forma de Pago:</strong> {orderDetail.extraDetails?.forma_pago || 'N/A'}
        </p>
        <p className="text-custom-gray-dark">
          <strong>Método de Pago:</strong> {orderDetail.extraDetails?.payment_method || 'N/A'}
        </p>
        <p className="text-custom-gray-dark">
          <strong>ID Transacción:</strong> {orderDetail.extraDetails?.transaction_id || 'N/A'}
        </p>
 
      </div>
      </div>

{/* Productos */}
<div className="relative overflow-x-auto rounded-sm shadow-md max-w-7xl mx-auto m-4">
  <table className="w-full text-left">
    <thead className="">
      <tr>
        <th scope="col" className="text-custom-blue px-6 py-3 rounded-s-lg">Nombre del Producto</th>
        <th scope="col" className="text-custom-blue px-6 py-3">Cantidad</th>
        <th scope="col" className="text-custom-blue px-6 py-3">Precio Unitario</th>
        <th scope="col" className="text-custom-blue px-6 py-3 rounded-e-lg">Total</th>
      </tr>
    </thead>
    <tbody>
      {orderDetail.lineItems.map((item, index) => (
        <tr key={index} className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"} `}>
         
          <th scope="row" className="px-6 py-4 font-medium">{item.product_name}</th>
          <td className="px-6 py-4">{item.quantity}</td>
          <td className="px-6 py-4">${item.price}</td>
          <td className="px-6 py-4">${(parseFloat(item.price) * item.quantity).toFixed(2)}</td>

        </tr>
      ))}
    </tbody>
    <tfoot>
      <tr className="font-semibold">
        <th scope="row" className="px-6 py-3 text-base">Total</th>
        <td className="px-6 py-3">
          {orderDetail.lineItems.reduce((sum, item) => sum + item.quantity, 0)}
        </td>
        <td></td>
        <td className="px-6 py-3">
          ${orderDetail.lineItems.reduce((sum, item) => sum + parseFloat(item.price) * item.quantity,0).toFixed(2)}
        </td>
      </tr>
    </tfoot>
  </table>
</div>

  
    </div>
  );
  
};

export default OrderDetails;