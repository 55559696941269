import React from 'react';
import ErrorsTable from '../components/tables/ErrorsTable';

const Errors: React.FC = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Errores</h1>
      <ErrorsTable />
    </div>
  );
};

export default Errors;