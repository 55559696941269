import React, { useState } from 'react';
import logo from '../assets/images/Logo_mdm.webp'; 
import { Link, useNavigate, Outlet, useLocation  } from 'react-router-dom';
//import { UserCircleIcon } from '@heroicons/react/24/solid';
import { BuildingStorefrontIcon, Squares2X2Icon,ChevronDownIcon,ExclamationTriangleIcon, ArchiveBoxIcon } from "@heroicons/react/24/outline";

interface DashboardProps {onLogout: () => void;}
const Dashboard: React.FC<DashboardProps> = ({ onLogout }) => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); 
  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
      <div className="flex-1 flex flex-col">
        <Header onLogout={handleLogout} onMenuClick={() => setIsSidebarOpen(!isSidebarOpen)} />
        <main className="flex-1 overflow-y-auto p-4">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose }) => {
  const location = useLocation(); 

  return (
    <div className={`fixed inset-y-0 left-0 z-50 w-62 bg-white shadow-md transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out lg:relative lg:translate-x-0`}>
      <div className="p-4 font-bold text-xl">
        <img src={logo} alt="Logo" className="w-36 h-auto mx-auto my-2" />
      </div>
      <nav className="mt-5">
        <ul>
    <li className={`p-4 ${location.pathname === '/home' ? 'text-custom-blue font-bold' : ''}`}>
      <Link to="/home">
        <div className="flex items-center">
          <Squares2X2Icon className="h-6 w-6 mr-2" />
          Dashboard
        </div>
      </Link>
    </li>
    <li className={`p-4 ${location.pathname === '/orders' ? 'text-custom-blue font-bold' : ''}`}>
      <Link to="/orders">
        <div className="flex items-center">
          <BuildingStorefrontIcon className="h-6 w-6 mr-2" />
          Pedidos
        </div>
      </Link>
    </li>
    <li className={`p-4 ${location.pathname === '/products' ? 'text-custom-blue font-bold' : ''}`}>
      <Link to="/products">
        <div className="flex items-center">
        <ArchiveBoxIcon className="h-6 w-6 " />
          Productos
        </div>
      </Link>
    </li>
    <li className={`p-4 ${location.pathname === '/errors' ? 'text-custom-blue font-bold' : ''}`}>
      <Link to="/errors">
        <div className="flex items-center">
          <ExclamationTriangleIcon className="h-6 w-6 mr-2" />
          Errores
        </div>
      </Link>
    </li>
        </ul>
      </nav>

      <button onClick={onClose} className="lg:hidden absolute top-4 right-4 text-gray-500 hover:text-gray-700">
        X
      </button>
    </div>
  );
};


const Header: React.FC<{ onLogout: () => void, onMenuClick: () => void }> = ({ onLogout, onMenuClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-white shadow-md p-4">
      <div className="flex justify-between items-center">
        <button className="lg:hidden text-gray-500 hover:text-gray-700" onClick={onMenuClick}>
          &#9776;
        </button>
        <div className="font-medium text-xl text-gray-800">Admin SGI</div>
      


     <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="focus:outline-none">
          <div className="flex items-center">
          <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
         <span className="font-medium text-gray-600 dark:text-gray-300">ADM</span>
        </div>
        <ChevronDownIcon className="h-6 w-6 p-1 text-gray-500" />
        </div>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md py-2">
          <a href="/profile" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
            Mi Perfil
          </a>
          <a href="/settings" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
            Configuración
          </a>
          <button onClick={onLogout} className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100">
            Cerrar Sesión
          </button>
        </div>
      )}
    </div>
       
      </div>
    </header>
  );
};

export default Dashboard;