import React, { useEffect, useMemo, useState } from 'react';
import { useTable, Column } from 'react-table';
import axiosInstance from '../../api/axiosInstance';

interface ErrorRecord {
  id: number;
  order_id: number;
  //transformed_client: string;
  //transformed_order: string;
  status: number;
  Procesado: boolean;
  message: string;
  error_date: string;
  modification_date: string;
}

const ErrorsTable: React.FC = () => {
  const [errors, setErrors] = useState<ErrorRecord[]>([]);

  const fetchErrors = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axiosInstance.get('/errors', {
        headers: { Authorization: `Bearer ${token}` },
      });

      const { data } = response.data;
      const formattedErrors = data.map((error: ErrorRecord) => ({
        ...error,
        error_date: new Date(error.error_date).toLocaleString(),
        modification_date: new Date(error.modification_date).toLocaleString(),
      }));

      setErrors(formattedErrors);
    } catch (error) {
      console.error('Error fetching error records:', error);
    }
  };

  useEffect(() => {
    fetchErrors();
  }, []);

  const columns: Column<ErrorRecord>[] = useMemo(
    () => [
      { Header: '#', accessor: 'id' as const },
      { Header: 'Pedido', accessor: 'order_id' as const },
     /*  {
        Header: 'Transformed Client',
        accessor: 'transformed_client' as const,
        Cell: ({ value }: { value: string }) => (
          <pre className="text-sm text-gray-500 break-words">{value}</pre>
        ),
      },
      {
        Header: 'Transformed Order',
        accessor: 'transformed_order' as const,
        Cell: ({ value }: { value: string }) => (
          <pre className="text-sm text-gray-500 break-words">{value}</pre>
        ),
      }, */
      { Header: 'Status', accessor: 'status' as const },
      { Header: 'Mensaje', accessor: 'message' as const },
      { Header: 'Fecha de error', accessor: 'error_date' as const },
      { Header: 'Fecha', accessor: 'modification_date' as const },
      {Header: 'Procesado', accessor: 'Procesado' as const,
        Cell: ({ value }: { value: boolean }) => (
          <span className={value ? 'text-green-500 font-semibold' : 'text-red-500 font-bold'}>
            {value ? 'Si': 'No'}
          </span>),}
          
          ,],[]
  );

  const tableInstance = useTable({ columns, data: errors });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div className="overflow-x-auto">
      <table {...getTableProps()} className="w-full table-auto divide-y divide-gray-200">
        {/* Tabla */}
        <thead className="bg-gray-50">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup-${headerGroup.id}`}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  key={`header-${column.id}`}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={`row-${row.original.id}`}>
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps()}
                    key={`cell-${cell.column.id}-${cell.row.id}`}
                    className="px-6 py-4 break-words text-sm text-gray-500">
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ErrorsTable;
